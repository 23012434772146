"use client";

import { useCompletion } from "ai/react";
import { useEffect, useRef, useState } from "react";
import { useOnboardingStore } from "./onboardingStore";
import "@/styles/md-container.css";
import { useAuthContext } from "@/providers/AuthProvider";
import { Button } from "@shadcn/button";
import ReportProgressStream, { StreamData } from "./report-progress-stream";
import { useNavigate } from "react-router-dom";

export default function ReportGeneration() {
  const navigate = useNavigate();
  const assessmentData = useOnboardingStore((state) => state.assessmentData);
  const northstar = useOnboardingStore((state) => state.northstar);
  const clearStore = useOnboardingStore((state) => state.clearStore);
  const { token } = useAuthContext();

  const url = process.env.REACT_APP_AI_API_URL;

  const isAllReportsProcessedRef = useRef(false);
  const [streamData, setStreamData] = useState<StreamData[]>([]);

  const { completion, complete, error } = useCompletion({
    api: url + "/v1/recommendations/onboarding/reports",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    streamProtocol: "text",
    onFinish: () => {
      isAllReportsProcessedRef.current = true;
    },
  });

  const hasCompletedRef = useRef(false);

  useEffect(() => {
    if (!hasCompletedRef.current && northstar && assessmentData) {
      hasCompletedRef.current = true;
      complete("", {
        body: {
          assessmentData: JSON.stringify(assessmentData),
          selectedNorthStar: northstar?.goal || "",
          selectedNorthStarDescription: northstar?.description || "",
        },
      });
    }
  }, [complete, assessmentData, northstar]);

  useEffect(() => {
    if (completion) {
      try {
        const lines = completion.split("\n");
        const parsedData = lines
          .filter((line) => line.trim() !== "")
          .map((line) => JSON.parse(line) as StreamData);
        setStreamData(parsedData);
      } catch (e) {
        console.error("Error parsing stream data:", e);
      }
    }
  }, [completion]);

  const handleGoToDashboard = () => {
    clearStore();
    navigate("/");
  };

  if (error) {
    return (
      <div className="text-center text-red-600">
        An error occurred while generating the report. Please try again.
        {error.message}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <>
        <h2 className="text-2xl font-bold text-primary">
          Generating Your Lifehack 360 Report
        </h2>
        <p className="text-gray-600">
          Your Big Northstar goal has been confirmed. Your Lifehack AI Coach is
          now generating a full Lifehack 360 Report, which includes a
          personalized plan to help you achieve your Northstar goal quicker.
        </p>
      </>
      <ReportProgressStream streamData={streamData} />
      {isAllReportsProcessedRef.current && (
        <div className="flex justify-center">
          <Button onClick={handleGoToDashboard}>Go to Dashboard</Button>
        </div>
      )}
    </div>
  );
}
