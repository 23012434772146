"use client";

import { useCompletion } from "ai/react";
import { useEffect, useRef, useState } from "react";
import { usePaywallStore } from "./paywallStore";
import "@/styles/md-container.css";
import { useAuthContext } from "@/providers/AuthProvider";
import { Button } from "@shadcn/button";
import ReportProgressStream, { StreamData } from "./report-progress-stream";

const NoAssessmentView = () => {
  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-primary">
        Want to Generate a New Report?
      </h2>
      <p className="text-gray-600">
        It looks like you've already generated a Lifehack 360 Report. To
        create a new report with different goals and preferences, you'll
        need to start fresh.
      </p>
      <p className="text-gray-600 mt-2">
        Head over to our Big Northstar creator to set new goals and generate
        an updated report.
      </p>
      <div className="flex justify-center">
        <a href="/create-big-northstar">
          <Button>Create New Big Northstar and Generate Report</Button>
        </a>
      </div>
    </div>
  );
};

const ReadyToGenerateView = ({ onConfirm }: { onConfirm: () => void }) => {
  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-primary">
        Ready to Generate Your Lifehack 360 Report
      </h2>
      <p className="text-gray-600">
        We&apos;re ready to create your personalized plan based on your
        Northstar goal. Click below to start generating your comprehensive
        Lifehack 360 Report.
      </p>
      <div className="flex justify-center">
        <Button onClick={onConfirm}>Generate My Report</Button>
      </div>
    </div>
  );
};

const GeneratingReportView = ({ 
  streamData, 
  isAllReportsProcessed 
}: { 
  streamData: StreamData[];
  isAllReportsProcessed: boolean;
}) => {
  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-primary">
        Generating Your Lifehack 360 Report
      </h2>
      <p className="text-gray-600">
        Your Big Northstar goal has been confirmed. Your Lifehack AI Coach is
        now generating a full Lifehack 360 Report, which includes a
        personalized plan to help you achieve your Northstar goal quicker.
      </p>
      <ReportProgressStream streamData={streamData} />
      {isAllReportsProcessed && (
        <div className="flex justify-center">
          <a href="/">
            <Button>Go to Dashboard</Button>
          </a>
        </div>
      )}
    </div>
  );
};

export default function ReportGeneration() {
  const assessmentData = usePaywallStore((state) => state.assessmentData);
  const northstar = usePaywallStore((state) => state.northstar);
  const resetStore = usePaywallStore((state) => state.resetStore);
  const { token } = useAuthContext();

  const url = process.env.REACT_APP_AI_API_URL;

  const isAllReportsProcessedRef = useRef(false);
  const [streamData, setStreamData] = useState<StreamData[]>([]);

  const { completion, complete, error } = useCompletion({
    api: url + "/v1/recommendations/onboarding/reports",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    streamProtocol: "text",
    onFinish: () => {
      isAllReportsProcessedRef.current = true;
      resetStore();
    },
  });

  const hasCompletedRef = useRef(false);

  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    if (
      !hasCompletedRef.current &&
      isConfirmed &&
      northstar &&
      assessmentData
    ) {
      hasCompletedRef.current = true;
      complete("", {
        body: {
          assessmentData: JSON.stringify(assessmentData),
          selectedNorthStar: northstar?.goal || "",
          selectedNorthStarDescription: northstar?.description || "",
        },
      });
    }
  }, [complete, assessmentData, northstar, isConfirmed]);

  useEffect(() => {
    if (completion) {
      try {
        const lines = completion.split("\n");
        const parsedData = lines
          .filter((line) => line.trim() !== "")
          .map((line) => JSON.parse(line) as StreamData);
        setStreamData(parsedData);
      } catch (e) {
        console.error("Error parsing stream data:", e);
      }
    }
  }, [completion]);

  if (error) {
    return (
      <div className="text-center text-red-600">
        An error occurred while generating the report. Please try again.
        {error.message}
      </div>
    );
  }

  if (!isConfirmed) {
    if (!assessmentData || Object.keys(assessmentData).length === 0) {
      return <NoAssessmentView />;
    }
    return <ReadyToGenerateView onConfirm={() => setIsConfirmed(true)} />;
  }

  return (
    <GeneratingReportView 
      streamData={streamData}
      isAllReportsProcessed={isAllReportsProcessedRef.current}
    />
  );
}
