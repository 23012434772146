
import { Logtail } from "@logtail/browser";
import { ILogtailLog } from "@logtail/types";
import { initializeAuth } from "./firebaseConfig";

async function enrichLogs(log: ILogtailLog): Promise<ILogtailLog> {
    const auth = await initializeAuth();

    const userId = auth.currentUser ? auth.currentUser.uid : 'guest';

    return {
        ...log,
        userId: userId,
        env: process.env.NODE_ENV? process.env.NODE_ENV : 'production',
    };
}
const logtail_key = process.env.REACT_APP_LOGTAIL_KEY;
if(!logtail_key) {
    throw new Error('Logtail key not found');
}
const log = new Logtail(logtail_key);
log.use(enrichLogs);

export default log;