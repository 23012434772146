'use client'

import { useLocation, useNavigate } from 'react-router-dom'
import Introduction from './introduction'
import AssessmentForm from './assessment-form'
import NorthstarSuggestion from './northstar-suggestion'
import ReportGeneration from './report-generation'
import { Stepper } from './stepper'
import { CheckoutPage } from './CheckoutPage'
import { usePaywallStore } from './paywallStore'
import { PaymentConfirmation } from './payment-confirmation'

export function Paywall() {
  const navigate = useNavigate()
  const location = useLocation()
  const isCheckoutReturn = location.pathname.includes('/start/step4a')
  const baseStep = Number(location.pathname.split('step')[1]) || 1
  const step = isCheckoutReturn ? 4 : baseStep
  const setAnswers = usePaywallStore((state) => state.setAnswers)

  const steps = ["Introduction", "Fill-in Assessment", "Confirm Northstar", "Confirm Subscription", "Generate Report"]

  const handleAssessmentSubmit = (answers: Record<string, string>) => {
    setAnswers(answers)
    navigate('/start/step3')
  }

  const handleNorthstarAcceptance = () => {
    navigate('/start/step4')
  }

  const handleNorthstarRejection = () => {
    navigate('/start/step2')
  }

  return (
    <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-4xl mx-auto">
        <div className="p-6">
          <h2 className="text-2xl font-bold">Welcome to Lifehack App</h2>
          <p className="text-gray-500">Your journey to achieving your Northstar starts here</p>
        </div>
        <div className="bg-white p-6 rounded-b-lg">
          <Stepper currentStep={step} steps={steps} />
          {step === 1 && <Introduction onContinue={() => navigate('/start/step2')} />}
          {step === 2 && <AssessmentForm onSubmit={handleAssessmentSubmit} />}
          {step === 3 && (
            <NorthstarSuggestion
              onAccept={handleNorthstarAcceptance}
              onReject={handleNorthstarRejection}
            />
          )}
          {step === 4 && (
            isCheckoutReturn 
              ? <PaymentConfirmation />
              : <CheckoutPage />
          )}
          {step === 5 && <ReportGeneration />}
        </div>
      </div>
    </div>
  )
}