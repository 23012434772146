import { Button } from "@shadcn/button"

interface IntroductionProps {
  onContinue: () => void
}

export default function Introduction({ onContinue }: IntroductionProps) {
  return (
    <div className="space-y-8 px-4 md:px-0">
      <div className="space-y-4">
        <h2 className="text-3xl font-bold text-primary">Discover Your Life&apos;s True Purpose</h2>
        <p className="text-lg text-gray-600">
          Take our free comprehensive assessment to uncover your Big Northstar Goal - your personalized mission that gives meaning and direction to everything you do.
        </p>
      </div>

      <div className="space-y-6 bg-gray-50 p-6 rounded-lg border border-gray-100">
        <div>
          <h3 className="text-xl font-semibold text-primary mb-2">Start For Free:</h3>
          <div className="space-y-4">
            <div className="flex items-start space-x-3">
              <div className="flex-shrink-0 w-6 h-6 rounded-full bg-green-100 text-green-600 flex items-center justify-center">✓</div>
              <div>
                <p className="font-medium">Comprehensive 360 Assessment</p>
                <p className="text-gray-600">Complete a 10-minute assessment to understand your full potential</p>
              </div>
            </div>
            <div className="flex items-start space-x-3">
              <div className="flex-shrink-0 w-6 h-6 rounded-full bg-green-100 text-green-600 flex items-center justify-center">✓</div>
              <div>
                <p className="font-medium">Big Northstar Goal Discovery</p>
                <p className="text-gray-600">Get AI-powered clarity on your life&apos;s mission and what truly drives you</p>
              </div>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-200 pt-6">
          <h3 className="text-xl font-semibold text-primary mb-2">Unlock Premium Features:</h3>
          <div className="space-y-4">
            <div className="flex items-start space-x-3">
              <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center">★</div>
              <div>
                <p className="font-medium">In-Depth 360 Report</p>
                <p className="text-gray-600">Unlock detailed insights and a personalized development roadmap</p>
              </div>
            </div>
            <div className="flex items-start space-x-3">
              <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center">★</div>
              <div>
                <p className="font-medium">AI Coach Support</p>
                <p className="text-gray-600">Get 24/7 guidance to achieve your goals faster</p>
              </div>
            </div>
            <div className="flex items-start space-x-3">
              <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center">★</div>
              <div>
                <p className="font-medium">Growth Mindset Prompts</p>
                <p className="text-gray-600">Break through mental blocks and limiting beliefs holding you back</p>
              </div>
            </div>
            <div className="flex items-start space-x-3">
              <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center">★</div>
              <div>
                <p className="font-medium">Premium Learning Library</p>
                <p className="text-gray-600">Access curated courses on productivity, time management, and success principles</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Button
        onClick={onContinue}
        className="w-full bg-primary text-white hover:bg-primary/90 flex items-center justify-center space-x-2 py-6 text-lg"
      >
        <span>Start Your Free Assessment</span>
      </Button>
    </div>
  )
}