import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import log from "../../../../utils/logtail";
import stripeBadges from "./stripe-badges.png";
import { useDocumentTitle } from "usehooks-ts";
import { Card, CardContent, CardHeader, CardTitle } from "@shadcn/card";
import { Badge } from "@shadcn/badge";
import { CalendarDays } from "lucide-react";
import { FileText, Zap, Clock, Bot } from "lucide-react";
import { Button } from "@shadcn/button";
import { FC } from "react";
import { Dialog, DialogContent } from "@shadcn/dialog";
import { usePaywallStore } from "../paywallStore";
import { useCompletion } from 'ai/react';

if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
  throw new Error("key is not set");
}

interface PricingCardsProps {
  selectedPlan: string;
  onPlanChange: (plan: "monthly" | "yearly") => void;
  onSubscribe: () => void;
}

interface CheckoutPopupProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  clientSecret: string;
  errorMessage: string;
  stripePromise: Promise<Stripe | null> | null;
}

interface FeatureItemProps {
  icon: React.ReactNode;
  title: string;
  text: string;
}

const FeatureItem: FC<FeatureItemProps> = ({ icon, title, text }) => (
  <div className="flex items-center py-4">
    <div className="flex-shrink-0 mr-4">
      <div className="p-3 bg-primary/10 rounded-full text-primary">{icon}</div>
    </div>
    <div>
      <h2 className="text-xl font-semibold">{title}</h2>
      <p className="text-muted-foreground">{text}</p>
    </div>
  </div>
);

interface Personalized360ReportProps {
  goalName: string;
}

const Personalized360Report: FC<Personalized360ReportProps> = ({
  goalName,
}) => {
  const assessment = usePaywallStore((state) => state.assessmentData);
  const northstar = usePaywallStore((state) => state.northstar);
  const hasGeneratedRef = useRef(false);

  const { completion: content, complete } = useCompletion({
    api: `${process.env.REACT_APP_AI_API_URL}/v1/recommendations/start`,
    onError: (error) => {
      console.error("Error generating report:", error);
    }
  });

  // Run once when data is available
  useEffect(() => {
    const generateReport = async () => {
      if (
        hasGeneratedRef.current || 
        !goalName || 
        !northstar?.goal ||
        !assessment ||
        !northstar?.description
      ) return;
      
      try {
        hasGeneratedRef.current = true;
        await complete('', {
          body: {
            firstName: "",
            assessmentData: assessment,
            selectedNorthStar: northstar.goal,
            selectedNorthStarDescription: northstar.description,
          }
        });
      } catch (error) {
        hasGeneratedRef.current = false;
        console.error('Failed to generate report:', error);
      }
    };

    // Add a small delay to ensure all data is properly hydrated
    const timer = setTimeout(() => {
      generateReport();
    }, 500);

    return () => clearTimeout(timer);
  }, [goalName, northstar?.goal, northstar?.description, complete, assessment]);

  return (
    <div className="flex items-center justify-center md:p-4">
      <div className="relative w-full overflow-hidden h-[420px] pt-6 md:px-4">
        <Card className="md:w-[72%] md:left-1/2 md:-translate-x-1/2 md:transform md:rotate-[2deg] shadow-xl bg-gray-100 absolute top-6">
          <CardHeader>
            <CardTitle className="text-2xl font-bold">
              <em>Preview of Personalized 360 Report</em> for{" "}
              <span className="bg-secondary/30 py-1 rounded-md inline-block">
                {goalName}
              </span>
            </CardTitle>
          </CardHeader>
          <CardContent>
            {content ? (
              <div className="prose prose-sm max-w-none">{content}</div>
            ) : (
              <div className="animate-pulse">
                <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
                <div className="h-4 bg-gray-200 rounded w-1/2 mb-4"></div>
                <div className="h-4 bg-gray-200 rounded w-5/6"></div>
              </div>
            )}
          </CardContent>
        </Card>
        <div className="absolute inset-0 bg-gradient-to-t from-white via-transparent to-transparent pointer-events-none"></div>
      </div>
    </div>
  );
};

const PricingCards: FC<PricingCardsProps> = ({
  selectedPlan,
  onPlanChange,
  onSubscribe,
}) => {
  return (
    <Card className="w-full max-w-4xl mx-auto bg-gray-50">
      <CardContent className="grid grid-cols-1 md:grid-cols-2 gap-6 p-6">
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <h3 className="text-xl font-semibold flex items-center gap-2">
              <CalendarDays className="w-6 h-6" />
              Monthly
            </h3>
            <Badge variant="secondary" className="ml-2">
              Popular
            </Badge>
          </div>
          <Card
            className={`bg-white border shadow-sm min-h-[150px] cursor-pointer transition-all ${
              selectedPlan === "monthly"
                ? "ring-2 ring-primary"
                : "hover:shadow-md"
            }`}
            onClick={() => onPlanChange("monthly")}
          >
            <CardContent className="p-4">
              <p className="text-4xl font-bold mb-1">
                $44.95
                <div className="text-lg font-normal text-muted-foreground">
                  / month
                </div>
              </p>
              <p className="text-sm text-muted-foreground">Billed monthly</p>
            </CardContent>
          </Card>
        </div>
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <h3 className="text-xl font-semibold flex items-center gap-2">
              <CalendarDays className="w-6 h-6" />
              Yearly
            </h3>
            <Badge variant="destructive" className="ml-2">
              35% OFF
            </Badge>
          </div>
          <Card
            className={`bg-white border shadow-sm relative overflow-hidden min-h-[150px] cursor-pointer transition-all ${
              selectedPlan === "yearly"
                ? "ring-2 ring-primary"
                : "hover:shadow-md"
            }`}
            onClick={() => onPlanChange("yearly")}
          >
            <CardContent className="p-4">
              <div className="absolute top-0 right-0 bg-red-500 text-white px-8 py-1 text-sm font-semibold transform rotate-45 translate-x-8 translate-y-4">
                Save $192
              </div>
              <p className="text-4xl font-bold mb-1">
                $29
                <div className="text-lg font-normal text-muted-foreground">
                  / month
                </div>
              </p>
              <p className="text-sm text-muted-foreground">Billed yearly</p>
            </CardContent>
          </Card>
        </div>
      </CardContent>
      <div className="p-6 pt-0">
        <Button className="w-full" size="lg" onClick={onSubscribe}>
          Get Your Personalized Plan + AI Coach
        </Button>
        <div className="text-sm mt-2 text-muted-foreground flex items-center justify-center gap-2">
          <svg
            className="w-5 h-5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>
            <strong>15-Day Money-Back Guarantee</strong> • No questions asked •
            Cancel anytime
          </span>
        </div>
      </div>
    </Card>
  );
};

const CheckoutPopup: FC<CheckoutPopupProps> = ({
  isOpen,
  onOpenChange,
  clientSecret,
  errorMessage,
  stripePromise,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px] p-2 py-10">
        {errorMessage && (
          <div className="flex justify-center items-center">
            <div className="bg-white p-3">{errorMessage}</div>
          </div>
        )}
        {clientSecret && (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret }}
          >
            <EmbeddedCheckout className="w-full" />
          </EmbeddedCheckoutProvider>
        )}
      </DialogContent>
    </Dialog>
  );
};

export const CheckoutPage = () => {
  const [plan, setPlan] = useState<"monthly" | "yearly">("monthly");
  const [clientSecret, setClientSecret] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
  const northstar = usePaywallStore((state) => state.northstar);

  const stripePromise = useMemo(() => {
    if (process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
      return loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    }
    return null;
  }, []);

  useDocumentTitle("Subscribe to Lifehack Membership");

  const fetchClientSecret = useCallback(() => {
    fetch(`${process.env.REACT_APP_EXTERNAL_API_URL}/v1/checkout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ plan }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret))
      .catch((error) => {
        console.error("Check out error");
        log.error("check out error: ", error);
        setErrorMessage("Failed to load checkout. Retrying...");
        if (process.env.REACT_APP_STRIPE_FALLBACK_CHECKOUT_PAGE) {
          window.location.href =
            process.env.REACT_APP_STRIPE_FALLBACK_CHECKOUT_PAGE;
        }
      });
  }, [plan]);

  useEffect(() => {
    fetchClientSecret();
  }, [fetchClientSecret]);

  const handlePlanChange = (newPlan: "monthly" | "yearly") => {
    setPlan(newPlan);
    setClientSecret(""); // Clear the client secret to trigger a re-fetch
  };

  const senjaContainerRef = useRef<HTMLDivElement>(null);

  const loadSenjaWidget = () => {
    if (document.querySelector('script[src*="widget.senja.io"]')) {
      return;
    }

    const script = document.createElement("script");
    script.src =
      "https://widget.senja.io/widget/e4e2dd30-fcba-4acf-9fb0-b660c2e52c76/platform.js";
    script.async = true;
    script.onload = () => {
      if (senjaContainerRef.current) {
        const senjaEmbed = document.createElement("div");
        senjaEmbed.className = "senja-embed";
        senjaEmbed.dataset.id = "e4e2dd30-fcba-4acf-9fb0-b660c2e52c76";
        senjaEmbed.dataset.mode = "shadow";
        senjaEmbed.dataset.lazyload = "false";
        senjaEmbed.style.display = "block";
        senjaContainerRef.current.appendChild(senjaEmbed);
        (window as any).Senja?.init();
      }
    };
    document.body.appendChild(script);
  };

  useEffect(() => {
    loadSenjaWidget();
  }, []);

  const handleSubscribe = () => {
    setIsCheckoutOpen(true);
  };

  return (
    <div id="checkout" className="w-full bg-white">
      <div>
        <Personalized360Report goalName={northstar?.goal || ""} />
        <div
          id="checkout-content"
          className="lg:flex lg:flex-row flex flex-col md:flex-row lg:justify-center lg:items-start"
        >
          <div className="order-2 md:order-1 w-full lg:w-[45%] py-6">
            <h1 className="text-3xl font-argent m-0 mb-6 p-0 leading-[1.3em]">
              Unlock Your Personalized Goal Plan for{" "}
              <span className="bg-secondary">{northstar?.goal}</span>
            </h1>
            <div>
              <FeatureItem
                icon={<FileText className="w-8 h-8" />}
                title="Your Personalized 360 Report"
                text="Start another Big Northstar by creating a new personalized 360 report anytime you want"
              />
              <FeatureItem
                icon={<Bot className="w-8 h-8" />}
                title="24/7 AI Life Coach"
                text="If you are stuck, ask the AI Life Coach anytime"
              />
              <FeatureItem
                icon={<Zap className="w-8 h-8" />}
                title="Daily Growth Prompts"
                text="Get daily prompts to reflect on your progress and break your procrastination cycle"
              />
              <FeatureItem
                icon={<Clock className="w-8 h-8" />}
                title="Learning Hub at your Fingertips"
                text="Access a library of wide range of premium content including high quality video courses to level up your skills"
              />
            </div>
          </div>
          <div className="order-1 md:order-2 w-full lg:w-[55%] lg:p-6 bg-white">
            <PricingCards
              selectedPlan={plan}
              onPlanChange={handlePlanChange}
              onSubscribe={handleSubscribe}
            />

            <CheckoutPopup
              isOpen={isCheckoutOpen}
              onOpenChange={setIsCheckoutOpen}
              clientSecret={clientSecret}
              errorMessage={errorMessage}
              stripePromise={stripePromise}
            />

            <div className="flex justify-center mt-4">
              <img src={stripeBadges} alt="Stripe Badges" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="p-6" ref={senjaContainerRef}></div> */}
    </div>
  );
};
