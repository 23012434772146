"use client";

import { useForm, FieldValues } from "react-hook-form";
import { Button } from "@shadcn/button";
import { Form } from "@shadcn/form";
import { PromptQuestion } from "@shared/components/prompts/question";
import prompts from "../Prompts/prompts.json";
import { useOnboardingStore } from "./onboardingStore";
import { Prompt } from "@shared/types";
import { mergeAnswersWithAssessmentData } from "./utils";
import { useEffect } from "react";
import Markdown from "markdown-to-jsx"
import "@/styles/md-container.css"

interface AssessmentFormProps {
  onSubmit: () => void;
}

export default function AssessmentForm({ onSubmit }: AssessmentFormProps) {
  const promptData = prompts["BR3q2sV0j5Nx6HiLcMfA"] as Prompt;
  const { answers, setAnswers, setAssessmentData, assessmentData } = useOnboardingStore();
  
  const form = useForm<FieldValues>({
    mode: "onChange",
    defaultValues: answers || {} // Set initial form values from store
  });
  
  const { formState: { errors, isSubmitted } } = form;

  // Load assessment data if not already in store
  useEffect(() => {
    if (!assessmentData) {
      setAssessmentData(promptData);
    }
  }, [assessmentData, setAssessmentData, promptData]);

  const handleSubmit = form.handleSubmit((data) => {
    setAnswers(data);
    setAssessmentData(mergeAnswersWithAssessmentData(data, promptData));
    onSubmit();
  });

  const hasErrors = Object.keys(errors).length > 0;

  return (
    <div className="pb-10">
      <Form {...form}>
        <form onSubmit={handleSubmit} className="space-y-6">
          <Markdown className="md-container pb-10">
{`
# Step 2: Your In-Depth Assessment

Great decision! You're now ready to dive into the assessment that will shape your personal growth journey. This isn't a test - it's an opportunity for deep self-reflection.

Here are some tips to get the most out of this process:

- **Be brutally honest:** There are no right or wrong answers. The more truthful you are, the more valuable your results will be.

- **Provide rich details:** Instead of simple yes/no answers, share examples and elaborate on your thoughts. The AI thrives on details to create a truly personalized plan.

- **Take your time:** This isn't a race. Feel free to pause, reflect, and even come back to questions later if you need more time to think.

- **Consider your whole life:** Try to give a balanced view of different aspects of your life - work, relationships, personal growth, etc.

- **It's okay to be uncertain:** If you're not sure about something, say so. That uncertainty itself can be valuable information.

Remember, you can always come back and update your answers later. In fact, you're encouraged to do so! As you progress through the assessment and start thinking about your Northstar Goal, you might have new insights to add.

Ready to start this journey of self-discovery? Let's dive in!      
`}
          </Markdown>
          <div className="flex flex-col gap-8 my-16">
            {promptData.questions.map((question, index) => {
              const answerKey = `question-${index}-${question.type}`;
              const mergedQuestion = {
                ...question,
                answer: answers ? answers[answerKey] : "",
              };
              return (
                <PromptQuestion
                  key={index}
                  question={mergedQuestion}
                  index={index}
                />
              );
            })}
          </div>
          <Button type="submit" className="bg-primary hover:bg-primary/90">
            Submit Assessment
          </Button>
          {isSubmitted && hasErrors && (
            <div className="mt-4 p-4 bg-red-100/50 border border-red-400/50 text-red-800 rounded">
              <p className="font-semibold">Please answer all required questions</p>
              <ul className="list-disc list-inside mt-2">
                <p>You have {Object.keys(errors).length} required questions left to answer.</p>
              </ul>
            </div>
          )}
        </form>
      </Form>
    </div>
  );
}