'use client'

import Introduction from './introduction'
import AssessmentForm from './assessment-form'
import NorthstarSuggestion from './northstar-suggestion'
import ReportGeneration from './report-generation'
import { Stepper } from './stepper'
import { useOnboardingStore } from './onboardingStore'

interface OnboardingProps {
  mode?: 'onboarding' | 'new-northstar'
}

export function Onboarding({ mode = 'onboarding' }: OnboardingProps) {
  const setStep = useOnboardingStore((state) => state.setStep);
  const step = useOnboardingStore((state) => state.step);

  const steps = ["Introduction", "Fill-in Assessment", "Confirm Northstar", "Generate Report"]

  const handleAssessmentSubmit = () => {
    setStep(3)
  }

  const handleNorthstarAcceptance = () => {
    setStep(4)
  }

  const handleNorthstarRejection = () => {
    setStep(2) // Go back to assessment if not accepted
  }

  const copywriting = {
    onboarding: {
      title: "Welcome to Lifehack App",
      subtitle: "Your journey to achieving your Northstar starts here"
    },
    'new-northstar': {
      title: "Create a New Big Northstar",
      subtitle: "Let's set a new ambitious goal for your journey"
    }
  }

  const { title, subtitle } = copywriting[mode]

  return (
    <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-4xl mx-auto">
        <div className="p-6">
          <h2 className="text-2xl font-bold">{title}</h2>
          <p className="text-gray-500">{subtitle}</p>
        </div>
        <div className="bg-white p-6 rounded-b-lg">
          <Stepper 
            currentStep={step} 
            steps={steps} 
            onStepClick={(stepNumber) => {
              if (stepNumber < step) {
                setStep(stepNumber)
              }
            }} 
          />
          {step === 1 && <Introduction onContinue={() => setStep(2)} />}
          {step === 2 && <AssessmentForm onSubmit={handleAssessmentSubmit} />}
          {step === 3 && (
            <NorthstarSuggestion
              onAccept={handleNorthstarAcceptance}
              onReject={handleNorthstarRejection}
            />
          )}
          {step === 4 && <ReportGeneration />}
        </div>
      </div>
    </div>
  )
}