import { useNavigate } from 'react-router-dom';
import { useFetchPromptAnswerByIsDashboard } from "@/queries/promptAnswers"
import ProcessingScreen from "./ProcessingScreen"
import LoadingIndicator from "@components/loading-indicator/LoadingIndicator"
import DashboardReport from "./DashboardReport"
import { PushPermission } from "./PushPermission"
import { BigNorthstar } from "./BigNorthstar/BigNorthStar"
import AskCoach from "./AskCoach"
import { useEffect } from 'react';
import { usePaywallStore } from '@/web/pages/Paywall/paywallStore';

export function Dashboard() {
  const navigate = useNavigate();
  const { data: promptAnswer, isLoading } = useFetchPromptAnswerByIsDashboard();
  const { assessmentData, report } = usePaywallStore();

  useEffect(() => {
    if (!isLoading && !promptAnswer) {
      // Check if assessment exists but report is not generated
      if (assessmentData && !report) {
        navigate('/start/step5');
        return;
      }
      
      // If no assessment data exists, start from beginning
      navigate('/onboarding');
    }
  }, [isLoading, promptAnswer, navigate, assessmentData, report]);

  if (isLoading) {
    return <LoadingIndicator />
  }

  if (!promptAnswer) {
    return null;
  }

  if (promptAnswer && (promptAnswer.isWaitingHumanCoach === undefined || promptAnswer.isWaitingHumanCoach === true)) {
    return <ProcessingScreen />
  }

  return (
    <div className="p-2 md:p-6">
      {promptAnswer && promptAnswer.isWaitingHumanCoach === false && (
        <>
          <PushPermission />
          <BigNorthstar />
          <AskCoach />
          <DashboardReport prompt={promptAnswer} />
        </>
      )}
    </div>
  )
}
