'use client'

import { useState, useEffect } from 'react'
import { Button } from "@shadcn/button"
import { Loader2 } from "lucide-react"
import { usePaywallStore } from './paywallStore'
import { useFetchAiPublicOnboardingGoalRecomendations } from '@/queries/ai'
import "@/styles/md-container.css"
import Markdown from "markdown-to-jsx"

interface NorthstarSuggestionProps {
  onAccept: () => void
  onReject: () => void
}

export default function NorthstarSuggestion({ onAccept, onReject }: NorthstarSuggestionProps) {
  const answers = usePaywallStore((state) => state.answers)
  const assessmentData = usePaywallStore((state) => state.assessmentData)
  const setNorthstar = usePaywallStore((state) => state.setNorthstar)

  const [userInput, setUserInput] = useState('')

  useEffect(() => {
    console.log('northstar-suggestion assessmentData', answers)
    if (answers && assessmentData) {
      const answeredQuestions = assessmentData.questions.map((q, index) => ({
        ...q,
        answer: answers[`question-${index}-${q.type}`] || "",
      }))
      setUserInput(JSON.stringify(answeredQuestions))
    }
  }, [answers, assessmentData])

  const { data, isLoading, error } = useFetchAiPublicOnboardingGoalRecomendations(userInput)

  const handleAccept = () => {
    if (data?.goal) {
      setNorthstar({
        goal: data.goal,
        description: data.description || ''
      })
      onAccept()
    }
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center space-x-2">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
        <span className="text-lg font-medium text-primary">Generating Northstar...</span>
      </div>
    )
  }

  if (error) {
    return <div className="text-red-500">Error generating Northstar. Please try again.</div>
  }

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-primary">Your Suggested Big Northstar</h2>
      <p className="text-gray-600">
        Based on your 360 Assessment, our AI Coach suggests the following Big Northstar for you:
      </p>
      <div className="bg-primary/10 p-4 rounded-md space-y-2">
        {data?.goal ? (
          <>
            <p className="text-3xl font-semibold text-primary">{data.goal}</p>
          </>
        ) : (
          <p className="text-gray-500">No Northstar suggestion available.</p>
        )}
      </div>
      <Markdown className="md-container">
        {data?.description || ''}
      </Markdown>
      <p className="text-gray-700 italic">
        Does this Big Northstar align with your vision? If so, click &quot;Accept&quot; to set it as your goal.
        If not, click &quot;Customize&quot; to go back and adjust your assessment.
      </p>
      <div className="space-x-4">
        <Button onClick={handleAccept} className="bg-primary hover:bg-primary/90">Accept</Button>
        <Button variant="outline" onClick={onReject} className="border-primary text-primary">Customize</Button>
      </div>
    </div>
  )
}