"use client";

import { useQuery } from "@tanstack/react-query";
import { Navigate } from "react-router-dom";

const fetchCheckoutStatus = async (sessionId: string | null) => {
  if (!sessionId) throw new Error("No session ID provided");
  const response = await fetch(
    `${process.env.REACT_APP_EXTERNAL_API_URL}/v1/checkout/status?session_id=${sessionId}`
  );
  return response.json();
};

export const PaymentConfirmation = () => {
  const sessionId = new URLSearchParams(window.location.search).get(
    "session_id"
  );

  const { data, isLoading } = useQuery({
    queryKey: ["checkoutStatus", sessionId],
    queryFn: () => fetchCheckoutStatus(sessionId),
    enabled: !!sessionId,
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
      </div>
    );
  }

  if (data?.status !== "complete") {
    return <Navigate to="/start/step4" />;
  }

  return (
    <div className="space-y-6 max-w-xl mx-auto">
      <div className="text-center">
        <div className="mb-4 text-green-500">
          <svg
            className="w-16 h-16 mx-auto"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
        <h2 className="text-2xl font-bold">Payment Successful!</h2>
        <p className="mt-2 text-gray-600">
          Thank you for your purchase. Please check your email{" "}
          <b>{data.customer_email}</b> to activate your account. Make sure to
          use the same browser to activate your account and continue with the
          report generation.
        </p>
        <p className="mt-8 text-sm text-gray-500">
          Tip: If you accidentally activated your account on a different device
          or browser, you can simply{" "}
          <a href="/login" className="text-primary underline">
            log in here
          </a>{" "}
          to continue with your report generation.
        </p>
      </div>
    </div>
  );
};
