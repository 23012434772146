"use client";

import { useForm } from "react-hook-form";
import { Button } from "@shadcn/button";
import { Form } from "@shadcn/form";
import { PromptQuestion } from "@shared/components/prompts/question";
import prompts from "../Prompts/prompts.json";
import { usePaywallStore } from "./paywallStore";
import { Prompt } from "@shared/types";
import { mergeAnswersWithAssessmentData } from "./utils";
import { useEffect } from "react";

interface AssessmentFormProps {
  onSubmit: (answers: Record<string, string>) => void;
}

export default function AssessmentForm({ onSubmit }: AssessmentFormProps) {
  const promptData = prompts["BR3q2sV0j5Nx6HiLcMfA"] as Prompt;
  const { answers, setAnswers, setAssessmentData } = usePaywallStore();
  const form = useForm();

  useEffect(() => {
    if (answers) {
      Object.entries(answers).forEach(([key, value]) => {
        form.setValue(key, value);
      });
    }
  }, [answers, form]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formValues = form.getValues();
    setAnswers(formValues);
    setAssessmentData(mergeAnswersWithAssessmentData(formValues, promptData));
    onSubmit(formValues);
  };

  return (
    <div className="pb-10">
      <Form {...form}>
        <form onSubmit={handleSubmit} className="space-y-6">
          <h2 className="text-2xl font-bold text-primary">
            Fill out your Lifehack 360 Assessment
          </h2>
          <p className="text-gray-600">
            Take 10-20 minutes to complete this assessment in a distraction-free
            environment. Your honest answers will help us provide the most
            accurate guidance.
          </p>
          <div className="flex flex-col gap-8 my-16">
            {promptData.questions.map((question, index) => {
              const answerKey = `question-${index}-${question.type}`;
              const mergedQuestion = {
                ...question,
                answer: answers ? answers[answerKey] : "",
              };
              return (
                <PromptQuestion
                  key={index}
                  question={mergedQuestion}
                  index={index}
                />
              );
            })}
          </div>
          <Button type="submit" className="bg-primary hover:bg-primary/90">
            Submit Assessment
          </Button>
        </form>
      </Form>
    </div>
  );
}